<template lang="pug">
  #wrapper
    SearchConditions
</template>

<script>
import SearchConditions from '@/components/SearchConditions/SearchConditions.vue'
export default {
  // コンポーネント
  components: {
    SearchConditions
  }
}
</script>

<style lang="stylus" scoped>
  #wrapper
    min-height 80vh
</style>